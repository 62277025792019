import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import facebook from '../img/icons/facebook.svg'
import instagram from '../img/icons/instagram.svg'

const Footer = () => {
    const [text, setText] = useState()

    const getText = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'texts',
                    type: 'name',
                    specific: 'footer'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setText(JSON.parse(response.data[0].text))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getText()
    }, [])

    return (
        <footer id='contact'>
            <div className='content'>
                <div className='contacts'>
                    <h3 className="name">Michal Suchomel</h3>
                    {text && <>
                        <p><a href={'tel:' + text?.phone.split(' ').join('')} className="phone"><FontAwesomeIcon icon={faPhone} />{text?.phone}</a></p>
                        <p><a href={'mail:' + text?.mail} className="mail"><FontAwesomeIcon icon={faEnvelope} />{text?.mail}</a></p>
                        <p><a href='https://www.google.com/maps/place/truhl%C3%A1%C5%99stv%C3%AD+Suchomel,+Horn%C3%AD+Libchavy+298,+561+16+Libchavy/@50.0146963,16.3834968,17z/data=!4m5!3m4!1s0x470def7e2821aa7f:0x93bc51b5873b87f3!8m2!3d50.0146963!4d16.3834968' className="address">
                            <FontAwesomeIcon icon={faLocationDot} />
                            {text?.address.street}<br />
                            {text?.address.postcode} {text?.address.city}
                        </a></p>
                        <div className="socials">
                            <a href={text?.socials.instagram} target="_blank" rel="noreferrer">
                                <img src={instagram} alt="Instagram logo" />
                            </a>
                            <a href={text?.socials.facebook} target="_blank" rel="noreferrer">
                                <img src={facebook} alt="Facebook logo" />
                            </a>
                        </div>
                    </>}
                </div>
                <div className='map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2563.821556637616!2d16.38130811641516!3d50.01469972654619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470def7e2821aa7f%3A0x93bc51b5873b87f3!2zdHJ1aGzDocWZc3R2w60gU3VjaG9tZWw!5e0!3m2!1scs!2scz!4v1662736343943!5m2!1scs!2scz" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Pozice truhlarstvi'></iframe>
                </div>
            </div>
            <p className='dcreative'>Vytvořeno s 💙 <a href="https://dcreative.cz" target="_blank" rel="noreferrer">Danielem Cvejnem</a></p>
        </footer>
    )
}

export default Footer