import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { About, Gallery, Menu, MenuButton, Footer } from '.'
import 'lightgallery/scss/lightgallery.scss'
import 'lightgallery/scss/lg-zoom.scss'
import 'lightgallery/css/lg-thumbnail.css'

const Main = () => {
    const [menuState, setMenuState] = useState(false)

    return (
        <>
            <Menu menuState={menuState} setMenuState={setMenuState} />
            <MenuButton setMenuState={setMenuState} menuState={menuState} />
            <header id='home'>
                <div className="content">
                    <div className="title">
                        <h1>Michal Suchomel</h1>
                        <p className="subtitle">truhlářství</p>
                    </div>
                    <a href="#about" className="continue">
                        <p>Pokračovat</p>
                        <FontAwesomeIcon icon={faAngleDown} />
                    </a>
                </div>
            </header>
            <main id="main">
                <About />
                <Gallery />
            </main>
            <Footer />
        </>
    )
}

export default Main