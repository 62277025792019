import React from 'react'
import { Routes , Route } from 'react-router-dom'
import './style/style.scss'
import { GallerySite, Main } from './components'

const App = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<Main />} />
                <Route path='/fotogalerie' element={<GallerySite />} />
            </Routes>
        </>
    )
}

export default App